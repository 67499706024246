@charset "UTF-8";
.sector-cards .sector-card-bottom ul, .project-cards .project-card-main ul, .project-cards .project-card-bottom ul, .top-spending, .metadata, .breadcrumbs ul, .project-header-inner-left ul, .oc-documents-feed, .oc-card-contacts-item .social-icons, .search-results .search-result-bottom ul, .oc-documents .oc-document-bottom ul, footer .footer-inner .footer-links ul, .oc-sidebar-top .sidebar-nav-tabs {
  list-style: none;
  margin: 0;
  padding: 0;
}

.request-popup .leaflet-popup-content-wrapper {
	background-color: #ffffff;
    border-radius: 11px;
    box-shadow: 0px 4px 9px #00000040;
	min-width: 350px;
  }
.login-form, .oc-sidebar-back {
  -webkit-box-shadow: 0 2px 4px rgba(20, 22, 28, 0.08);
          box-shadow: 0 2px 4px rgba(20, 22, 28, 0.08);
}

.oc-team-grid-item .oc-card-team-img {
  -webkit-box-shadow: 0 10px 60px 0 rgba(20, 22, 28, 0.08);
          box-shadow: 0 10px 60px 0 rgba(20, 22, 28, 0.08);
}

/* Global styles */
* {
  font-family: "Inter", sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #fafafa;
  color: #14161C;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
  text-rendering: optimizeLegibility;
}

img {
  height: auto;
  max-width: 100%;
}

/* Typography */
.MuiTypography-root {
  font-size: 14 !importantpx;
  font-size: 14 !important/16rem;
  line-height: 14 !important6px;
}

p.MuiTablePagination-selectLabel, p.MuiTablePagination-displayedRows {
  margin-bottom: 0;
}

a {
  color: #2E3BAE;
  text-decoration: none;
}

.styled-heading::before {
  background-color: #ff3514;
  content: "";
  display: inline-block;
  font-weight: 500;
  height: 15px;
  margin-right: 10px;
  width: 3px;
}

.styled-heading-large {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 26px;
  margin-bottom: 30px;
}

.styled-heading-small {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 21px;
  margin-bottom: 13px;
}

.oc-heading-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 5px;
}

.oc-heading-group h4 {
  margin-bottom: 8px;
}

.stat-title-small {
  color: #6A6971;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
}

.stat-large {
  display: block;
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 36px;
}

.stat-medium {
  display: block;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 26px;
}

.sidebar-title {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
}

.ic-content-title {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 0;
}

.alert {
  border-radius: 0;
  font-weight: 500;
}

.oc-charts-yaxis-label {
  color: #14161C !important;
  font-weight: 500;
  opacity: 1;
  text-transform: capitalize;
}

.MuiAlert-message a {
  font-weight: 500;
  text-decoration: underline;
}

/* Colours */
.success {
  color: #108214;
}

.primary {
  color: #2E3BAE;
}

.bg-success {
  background-color: #108214 !important;
}

.bg-primary {
  background-color: #2E3BAE !important;
}

.bg-danger {
  background-color: #CF0303 !important;
}

.bg-warning {
  background-color: #F2A93B !important;
}

.danger {
  color: #CF0303;
}

.apexcharts-tooltip {
  background: #E5E5E5;
  color: #14161C;
}

/* Cards */
.oc-card {
  background-color: #fff;
  border: 1px solid #E5E5E5;
  height: 100%;
  padding: 20px;
}

.oc-card.no-padding {
  padding: 0;
}

.oc-card-centered {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.oc-card-small-heading {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
}

.oc-card-small-link {
  color: #ff3514;
  font-weight: 600;
  text-decoration: underline;
}

.oc-card-small-link:hover {
  color: #ff3514;
}

.oc-card-stat span.stat-title-small {
  color: #6A6971;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
}

.oc-card-stat span.stat-title-small .tooltip-icon {
  color: #ff3514;
  cursor: pointer;
  margin-left: 5px;
}

.oc-card-stat span.stat-large {
  display: block;
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 36px;
}

.oc-card-sectors {
  display: -ms-grid;
  display: grid;
  gap: 20px 20px;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.oc-card-sectors .oc-card-sector {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #E5E5E5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 25px 20px;
  text-align: center;
}

.oc-card-sectors .oc-card-sector-icon {
  margin-bottom: 10px;
}

.oc-card-sectors .oc-card-sector-txt h6 {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  margin-bottom: 1px;
}

.oc-card-sectors .oc-card-sector-txt h6 a {
  color: #14161C;
}

.oc-card-sectors .oc-card-sector-txt span {
  color: #6A6971;
}

.oc-card-grid {
  display: -ms-grid;
  display: grid;
  gap: 0;
}

.oc-card-grid.grid-2 {
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.oc-card-grid.grid-3 {
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
}

.oc-card-grid-item {
  background-color: #fff;
  border: 1px solid #E5E5E5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 20px;
  text-align: center;
}

@media (max-width: 1023.98px) {
  .oc-card {
    height: auto;
    margin-bottom: 20px;
  }
  .oc-card-sectors {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .oc-card-grid {
    height: auto;
    margin-bottom: 20px;
  }
  .oc-card-grid.grid-sm-1 {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767.98px) {
  .oc-card-sectors {
    display: block;
  }
  .oc-card-sectors .oc-card-sector {
    margin-bottom: 10px;
  }
}

.oc-map-card {
  min-height: 300px;
  overflow: hidden;
}

.oc-card-empty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

.oc-card-empty p {
  color: #E5E5E5;
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 0;
}

.oc-split-card {
  background-color: #fff;
  border: 1px solid #E5E5E5;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
}

.oc-split-card-item {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #E5E5E5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px;
}

.oc-split-card-item:last-of-type {
  border-bottom: none;
}

.oc-split-card-item .split-card-icon {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 46px;
}

.sector-cards {
  display: -ms-grid;
  display: grid;
  gap: 20px 20px;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.sector-cards .sector-card {
  background-color: #fff;
  border: 1px solid #CFCFCF;
}

.sector-cards .sector-card-top {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #CFCFCF;
  padding: 15px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sector-cards .sector-card-top-icon {
  margin-right: 15px;
}

.sector-cards .sector-card-top-info h6 {
  margin-bottom: 3px;
}

.sector-cards .sector-card-top-info h6 a {
  color: #ff3514;
}

.sector-cards .sector-card-top-info h6 a:hover {
  text-decoration: underline;
}

.sector-cards .sector-card-top-info p {
  margin-bottom: 0;
}

.sector-cards .sector-card-bottom {
  padding: 15px 20px;
}

.sector-cards .sector-card-bottom ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sector-cards .sector-card-bottom ul li {
  margin-right: 20px;
}

.sector-cards .sector-card-bottom ul li span {
  font-weight: 600;
}

@media (max-width: 1023.98px) {
  .sector-cards {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .sector-cards .sector-card-bottom ul {
    display: block;
  }
}

@media (max-width: 1023.98px) {
  .sector-cards .sector-card-top {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.project-cards .project-card {
  background-color: #fff;
  border: 1px solid #CFCFCF;
  cursor: pointer;
  margin-bottom: 15px;
}

.project-cards .project-card-main {
  border-bottom: 1px solid #CFCFCF;
  padding: 15px 20px;
}

.project-cards .project-card-main h5 {
  color: #ff3514;
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  margin-bottom: 1px;
}

.project-cards .project-card-main h5 a {
  color: #ff3514;
}

.project-cards .project-card-main h5 a:hover {
  text-decoration: underline;
}

.project-cards .project-card-main ul {
  color: #6A6971;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}

.project-cards .project-card-main ul li span {
  font-weight: 500;
}

.project-cards .project-card-main ul li::after {
  content: "·";
  padding: 0 5px;
}

.project-cards .project-card-main ul li:last-child::after {
  display: none;
}

.project-cards .project-card-main p {
  margin-bottom: 0;
}

.project-cards .project-card-bottom {
  padding: 15px 20px;
}

.project-cards .project-card-bottom ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.project-cards .project-card-bottom ul li {
  margin-right: 15px;
}

.project-cards .project-card-bottom ul li span {
  font-weight: 600;
}

.oc-budget-cards {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  gap: 20px 20px;
}

.oc-budget-cards .oc-budget-card h6 {
  color: #6A6971;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.oc-budget-cards .oc-budget-card-stat {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.oc-budget-cards .oc-budget-card-stat p {
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 46px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.oc-budget-cards .oc-budget-card-stat span {
  display: none;
  margin-left: 10px;
}

.oc-budget-cards .oc-budget-card-stat span.trend {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.oc-budget-cards .oc-budget-card-stat span.trend-up {
  border-bottom: 10px solid;
}

.oc-budget-cards .oc-budget-card-stat span.trend-down {
  border-top: 10px solid;
}

@media (max-width: 1023.98px) {
  .oc-budget-cards {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    gap: 0 20px;
  }
}

@media (max-width: 767.98px) {
  .oc-budget-cards {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }
}

.oc-icon-card {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.oc-icon-card-img {
  margin-right: 10px;
}

.oc-icon-card-img img {
  height: auto;
  max-width: 50px;
}

.oc-icon-card-txt h6 {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.oc-icon-card-txt p {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 31px;
  margin-bottom: 0;
}

.oc-summary-card {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

.oc-summary-card span {
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  margin-bottom: 10px;
}

.oc-summary-card p {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 0;
}

.top-spending {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 19px;
}

.top-spending li {
  border-bottom: 1px solid #CFCFCF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 0;
}

.top-spending li:hover {
  background-color: rgba(229, 229, 229, 0.2);
}

.top-spending li p {
  margin-bottom: 0;
  max-width: 400px;
  text-transform: capitalize;
  white-space: wrap;
}

.top-spending li span {
  display: block;
  font-weight: 600;
  text-transform: uppercase;
}

.metadata li {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #E5E5E5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 0;
}

.metadata li:last-child {
  border-bottom: none;
}

.metadata li p {
  font-weight: 300;
  margin-bottom: 0;
  width: 15%;
}

.metadata li span {
  display: block;
  font-weight: 500;
}

@media (max-width: 991.98px) {
  .metadata li {
    display: block;
  }
  .metadata li p {
    width: auto;
  }
}

.breadcrumbs {
  background-color: #fff;
  border-bottom: 1px solid #E5E5E5;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 19px;
  padding: 10px 0;
  position: relative;
  z-index: 2;
}

.breadcrumbs ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.breadcrumbs ul li::after {
  content: "/";
  display: inline-block;
  padding: 0 8px;
}

.breadcrumbs ul li:last-child::after {
  display: none;
}

.breadcrumbs ul li a {
  color: #6A6971;
}

.breadcrumbs ul li a:hover {
  text-decoration: underline;
}

@media (max-width: 767.98px) {
  .breadcrumbs ul li span {
    display: block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* Forms */
.hero-search .form-control-lg {
  border-radius: 5px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
}

.hero-search button.btn {
  border-color: #F2A93B;
}

.MuiFormControl-root .MuiSelect-select {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
}

.MuiOutlinedInput-root .MuiAutocomplete-input {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
}

.form-control, .form-select {
  border-radius: 0;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
}

.form-control-lg, .form-select-lg {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 26px;
}

.form-control:focus, .form-control:active, .form-select:focus, .form-select:active {
  border-color: #14161C;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.form-select {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.col-form-label, .form-select-small {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 19px;
}

.form-check-input[type=checkbox] {
  border-radius: 0;
}

.form-check-label {
  font-weight: 400;
}

/* Tables */
div.dataTables_wrapper div.dataTables_info {
  display: none;
}

.data-table {
  background-color: #fff;
}

.data-table thead tr:first-of-type th {
  background-color: #E5E5E5;
}

.data-table th {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 19px;
  line-height: 18px;
}

.data-table td {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 19px;
}

.data-table td a {
  color: #14161C;
  font-weight: 500;
  text-decoration: underline;
}

.data-table caption.caption {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 18px;
}

.table-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-bottom: 20px;
}

@media (max-width: 767.98px) {
  .table-toolbar {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .table-toolbar button {
    width: 100%;
  }
}

.MuiTableContainer-root {
  min-width: auto !important;
}

/* Pagination */
.data-table-nav, .list-nav {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
  border: 1px solid #CFCFCF;
  color: #14161C;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 19px;
  overflow: hidden;
  padding-left: 15px;
}

.data-table-nav p, .list-nav p {
  margin-bottom: 0;
}

.data-table-nav .pagination, .list-nav .pagination {
  margin-bottom: 0;
  margin-left: 15px;
}

.data-table-nav .pagination .page-link, .list-nav .pagination .page-link {
  border: none;
  color: #14161C;
}

.list-nav {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

/* Accordion */
.accordion-item {
  border-color: #E5E5E5;
}

.accordion-button {
  color: #14161C;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
  padding: 15px 0;
}

.accordion-button:focus, .accordion-button:active, .accordion-button:not(.collapsed) {
  background-color: #fff;
  color: #14161C;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.accordion-button:focus::after, .accordion-button:active::after, .accordion-button:not(.collapsed)::after {
  color: #14161C;
}

.accordion-button span {
  color: #6A6971;
  margin-right: 10px;
}

.accordion-body {
  padding: 5px 0 15px 0;
}

.accordion-body ul {
  margin: 0;
}

/* Projects */
.project-header {
  background-color: #fff;
  padding: 20px 0;
}

.project-header-inner {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.project-header-inner-left h2 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  margin-bottom: 1px;
}

.project-header-inner-left ul {
  color: #6A6971;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.project-header-inner-left ul li span {
  font-weight: 500;
}

.project-header-inner-left ul li::after {
  content: "·";
  padding: 0 5px;
}

.project-header-inner-left ul li:last-child::after {
  display: none;
}

@media (min-width: 1024px) {
  .project-header-inner-left {
    max-width: 800px;
  }
}

@media (max-width: 767.98px) {
  .project-header-inner {
    display: block;
  }
  .project-header-inner-left {
    margin-bottom: 15px;
  }
  .project-header-inner-left h2 {
    margin-bottom: 5px;
  }
}

.status-badge {
  background-color: #6A6971;
  border-radius: 30px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 18px;
  color: #fff;
  padding: 8px 12px;
}

.project-details .nav-tabs {
  border-bottom: 1px solid #CFCFCF;
}

.project-details .nav-tabs .nav-link {
  background-color: #CFCFCF;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #6A6971;
  font-weight: 500;
  padding: 15px 20px;
}

.project-details .nav-tabs .nav-link.active {
  background-color: #2E3BAE;
  color: #fff;
}

.project-details .tab-content {
  background-color: #fff;
}

.project-details .tab-content .tab-pane .project-info {
  color: #6A6971;
  padding: 20px;
}

.project-details .tab-content .tab-pane .project-info-item {
  margin-bottom: 20px;
}

.project-details .tab-content .tab-pane .project-info-item h6 {
  color: #14161C;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 4px;
}

.project-details .tab-content .tab-pane .project-info-item p {
  margin-bottom: 0;
}

.project-details .tab-content .tab-pane .project-info-item ul {
  margin: 0;
}

.project-details .tab-content .tab-pane .project-info-item:last-of-type {
  margin-bottom: 0;
}

.project-details .tab-content .tab-pane .project-map {
  height: 100%;
  overflow: hidden;
  padding: 20px;
  position: relative;
}

.project-details .tab-content .tab-pane .project-map iframe {
  border: 1px solid #6A6971;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.project-details .tab-content .tab-pane .project-images {
  padding: 20px;
}

/* About */
.oc-card-split {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 767.98px) {
  .oc-card-split {
    display: block;
  }
}

.county-logo {
  margin-right: 30px;
}

.county-logo img {
  height: auto;
  max-width: 140px;
}

@media (max-width: 767.98px) {
  .county-logo {
    margin: 0 0 15px;
  }
  .county-logo img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

.county-info p:last-of-type {
  margin-bottom: 0;
}

.icon-grid {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  gap: 30px 20px;
  height: 100%;
  padding: 30px;
  text-align: center;
}

.icon-grid-item img {
  display: block;
  height: 50px;
  margin: 0 auto 10px;
  width: 50px;
}

.icon-grid-item-title {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 20px;
  text-transform: uppercase;
}

.icon-grid-item-number {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 31px;
}

@media (max-width: 767.98px) {
  .icon-grid {
    gap: 20px 20px;
    padding: 0;
  }
  .icon-grid-item-number {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 26px;
  }
}

.oc-team-grid {
  display: -ms-grid;
  display: grid;
  gap: 20px 20px;
  -ms-grid-columns: (1fr)[4];
      grid-template-columns: repeat(4, 1fr);
  text-align: center;
}

.oc-team-grid-item {
  text-align: center;
}

.oc-team-grid-item .oc-card-team-img {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 170px;
  margin: 0 auto 15px;
  overflow: hidden;
  width: 170px;
}

.oc-team-grid-item .oc-card-team-img img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
}

.oc-team-grid-item .oc-card-team-info h6 {
  margin-bottom: 5px;
}

.oc-team-grid-item .oc-card-team-info p {
  color: #ff3514;
  margin-bottom: 0;
}

@media (max-width: 1023.98px) {
  .oc-team-grid {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1023.98px) {
  .oc-team-grid {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
}

.oc-documents-feed {
  display: -ms-grid;
  display: grid;
  gap: 10px 10px;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  text-align: center;
}

.oc-documents-feed-item {
  overflow: hidden;
}

.oc-documents-feed-item a {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #E5E5E5;
  color: #14161C;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 20px;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  /* easeOutQuint */
}

.oc-documents-feed-item a:hover {
  border-color: #6A6971;
}

.oc-documents-feed-item a span {
  color: #ff3514;
  display: block;
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 46px;
  margin-bottom: 10px;
}

.oc-documents-feed-item a p {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 19px;
  margin-bottom: 0;
  text-overflow: ellipsis;
}

@media (max-width: 767.98px) {
  .oc-documents-feed {
    display: block;
  }
}

.oc-card-contacts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.oc-card-contacts-item {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  margin-bottom: 25px;
  padding-right: 30px;
}

.oc-card-contacts-item h6 {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 3px;
}

.oc-card-contacts-item p {
  margin-bottom: 3px;
}

.oc-card-contacts-item p span {
  color: #ff3514;
  margin-right: 10px;
}

.oc-card-contacts-item p a {
  color: #14161C;
}

.oc-card-contacts-item p:last-of-type {
  margin-bottom: 0;
}

.oc-card-contacts-item:last-of-type {
  margin-bottom: 0;
}

.oc-card-contacts-item .social-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.oc-card-contacts-item .social-icons li a {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
  border: 2px solid #6A6971;
  border-radius: 50%;
  color: #6A6971;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 5px 5px 0;
  width: 35px;
}

.oc-card-contacts-item .social-icons li a:hover {
  border-color: #14161C;
  color: #14161C;
}

@media (max-width: 767.98px) {
  .oc-card-contacts {
    display: block;
  }
}

/* Search Results */
.search-results .search-result {
  background-color: #fff;
  border: 1px solid #CFCFCF;
  margin-bottom: 25px;
}

.search-results .search-result-top {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #CFCFCF;
  padding: 15px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.search-results .search-result-top-icon {
  margin-right: 15px;
}

.search-results .search-result-top-info h6 {
  margin-bottom: 3px;
}

.search-results .search-result-top-info h6 a {
  color: #ff3514;
}

.search-results .search-result-top-info h6 a:hover {
  text-decoration: underline;
}

.search-results .search-result-top-info h6 a span {
  background-color: yellow;
}

.search-results .search-result-top-info p {
  margin-bottom: 0;
}

.search-results .search-result-top-info p span {
  font-weight: 600;
}

.search-results .search-result-bottom {
  padding: 15px 20px;
}

.search-results .search-result-bottom ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.search-results .search-result-bottom ul li {
  margin-right: 20px;
}

.search-results .search-result-bottom ul li span {
  font-weight: 600;
}

@media (max-width: 1023.98px) {
  .search-results .search-result-bottom ul {
    display: block;
  }
}

/* Documents */
.oc-documents .oc-document {
  background-color: #fff;
  border: 1px solid #CFCFCF;
  margin-bottom: 25px;
}

.oc-documents .oc-document-top {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #CFCFCF;
  padding: 15px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.oc-documents .oc-document-top-icon {
  color: #6A6971;
  margin-right: 10px;
}

.oc-documents .oc-document-top-info h6 {
  font-weight: 500;
  margin-bottom: 0;
}

.oc-documents .oc-document-top-info h6 a {
  color: #ff3514;
}

.oc-documents .oc-document-top-info h6 a:hover {
  text-decoration: underline;
}

.oc-documents .oc-document-top-info h6 a .doc-icon {
  margin-right: 8px;
}

.oc-documents .oc-document-top-info p {
  margin-bottom: 0;
}

.oc-documents .oc-document-top-info p span {
  font-weight: 600;
}

.oc-documents .oc-document-bottom {
  padding: 15px 20px;
}

.oc-documents .oc-document-bottom ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.oc-documents .oc-document-bottom ul li {
  margin-right: 20px;
}

.oc-documents .oc-document-bottom ul li span {
  font-weight: 600;
  text-transform: capitalize;
}

@media (max-width: 1023.98px) {
  .oc-documents .oc-document-top {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .oc-documents .oc-document-bottom ul {
    display: block;
  }
}

/* Budgets */
.budget-cards {
  display: -ms-grid;
  display: grid;
  gap: 20px 20px;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  position: relative;
}

.budget-cards::after {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #ff3514;
  border: 3px solid #F3F6FB;
  border-radius: 50%;
  color: #fff;
  content: "VS";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-weight: 700;
  height: 45px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 45px;
}

.budget-cards .budget-card {
  background-color: #6A6971;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.budget-cards .budget-card.active {
  background-color: #2E3BAE;
}

.budget-cards .budget-card-top {
  border-bottom: 1px solid;
  padding: 30px;
}

.budget-cards .budget-card-top span {
  display: block;
}

.budget-cards .budget-card-top-title {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
}

.budget-cards .budget-card-top-value {
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 46px;
  text-transform: uppercase;
}

.budget-cards .budget-card-bottom {
  padding: 10px 30px;
}

.budget-cards .budget-card-bottom span {
  display: block;
}

.budget-cards .budget-card-bottom-text {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
}

/* Login */
.wrapper-login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.login-image {
  height: 300px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.login-image-overlay {
  background-color: rgba(20, 22, 28, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}

.login-image img {
  bottom: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media (max-width: 767.98px) {
  .login-image {
    display: none;
  }
  .login-image img {
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center center;
       object-position: center center;
  }
}

.login-form {
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 30px;
  position: relative;
  width: 445px;
  z-index: 3;
}

.login-form-header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 30px;
}

.login-form-header h1 {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 26px;
  margin-bottom: 0;
}

.login-form-logo {
  margin-bottom: 30px;
}

.login-form-logo img {
  display: block;
  margin: 0 auto;
  max-width: 200px;
}

.login-form-fields {
  margin-bottom: 30px;
}

.login-form-fields .form-label, .login-form-fields .form-check-label {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 19px;
}

.login-form-bottom {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 19px;
}

.login-form-bottom p .reset-pass-link {
  color: #14161C;
  font-weight: 600;
  text-decoration: underline;
}

@media (max-width: 767.98px) {
  .login-form {
    width: 100%;
  }
}

/* Header */
.header-desktop {
  background-color: #fff;
  border-bottom: 1px solid #E5E5E5;
  position: relative;
  z-index: 2;
}

@media (min-width: 1024px) {
  .header-mobile {
    display: none;
  }
}

.top-bar {
  border-bottom: 1px solid #E5E5E5;
  padding: 15px 0;
}

.top-bar-inner {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.top-bar-inner .top-bar-left {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.top-bar-inner .top-bar-left .site-logo {
  margin-right: 30px;
  max-width: 120px;
}

.top-bar-inner .top-bar-left .top-bar-search .form-control {
  border: 1px solid #6A6971;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #14161C;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 19px;
}

.top-bar-inner .top-bar-left .top-bar-search .form-control:hover, .top-bar-inner .top-bar-left .top-bar-search .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.top-bar-inner .top-bar-right .navbar-nav .nav-item.dropdown .avatar {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #F2A93B;
  border-radius: 50%;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-weight: 500;
  height: 40px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 40px;
}

.top-bar-inner .top-bar-right .navbar-nav .nav-item.dropdown .dropdown-menu {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
}

@media (max-width: 1023.98px) {
  .top-bar-inner .top-bar-search {
    display: none;
  }
}

.main-nav {
  padding: 8px 0;
}

.main-nav .navbar-toggler {
  background-color: #fff;
  border: none;
  border-radius: 0;
  color: #14161C;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
  padding: 12px 0;
}

.main-nav .navbar-toggler:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-nav .navbar-toggler .svg-inline--fa, .main-nav .navbar-toggler .mobile-menu-icon {
  margin-right: 5px;
}

.main-nav .navbar-nav .nav-item .nav-link {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
  color: #14161C;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
  margin: 0 3px;
  padding: 10px;
  width: 100%;
}

.main-nav .navbar-nav .nav-item .nav-link .menu-icon, .main-nav .navbar-nav .nav-item .nav-link .svg-inline--fa {
  margin-right: 8px;
}

.main-nav .navbar-nav .nav-item .nav-link:hover .menu-icon {
  color: #108214;
}

.main-nav .navbar-nav .nav-item .nav-link.active {
  color: #108214;
}

.main-nav .navbar-nav .nav-item .nav-link.active .menu-icon {
  color: #108214;
}

@media (max-width: 1023.98px) {
  .main-nav {
    padding: 0;
  }
  .main-nav .navbar-collapse {
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 10;
  }
}

.navbar-brand img {
  max-width: 220px;
}

/* Footer */
footer {
  background-color: #fff;
  color: #6A6971;
  border-top: 1px solid #E5E5E5;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 19px;
  padding: 17px 0;
  position: relative;
}

footer .footer-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

footer .footer-inner .colophon p {
  margin-bottom: 0;
}

footer .footer-inner .colophon p a {
  color: #6A6971;
  font-weight: 600;
  text-decoration: underline;
}

footer .footer-inner .colophon p a .svg-inline--fa {
  margin-left: 5px;
}

footer .footer-inner .footer-links ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

footer .footer-inner .footer-links ul li a {
  color: #6A6971;
  display: block;
  font-weight: 600;
}

footer .footer-inner .footer-links ul li a::after {
  content: "·";
  padding: 10px;
}

footer .footer-inner .footer-links ul li:last-child a::after {
  display: none;
}

@media (max-width: 1023.98px) {
  footer .footer-inner {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  footer .footer-inner .colophon {
    margin-bottom: 5px;
  }
}

/* Sidebar */
.oc-sidebar-back {
  background-color: #fff;
  bottom: -120px;
  position: fixed;
  top: 0;
  width: 300px;
  z-index: 0;
}

.oc-sidebar {
  background-color: #fff;
  float: left;
  height: 100%;
  overflow: auto;
  position: relative;
  width: 300px;
}

.oc-sidebar-top {
  height: 70px;
}

.oc-sidebar-top .sidebar-tab {
  background-color: #E5E5E5;
  color: #6A6971;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 19px;
  min-height: 70px;
  height: 100%;
  letter-spacing: normal;
  padding: 0;
  text-transform: capitalize;
  width: 50%;
}

.oc-sidebar-top .sidebar-tab .svg-inline--fa {
  margin-bottom: 0;
  margin-right: 8px;
}

.oc-sidebar-top .sidebar-tab.Mui-selected {
  background-color: #ff3514;
  color: #fff;
}

.oc-sidebar-top .MuiTabs-indicator {
  display: none;
}

.oc-sidebar-top .sidebar-nav-tabs {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 19px;
  height: 100%;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.oc-sidebar-top .sidebar-nav-tabs .svg-inline--fa {
  margin-right: 8px;
}

.oc-sidebar-top .sidebar-nav-tabs li {
  height: 100%;
  width: 100%;
}

.oc-sidebar-top .sidebar-nav-tabs li .sidebar-nav-link {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #E5E5E5;
  color: #6A6971;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.oc-sidebar-top .sidebar-nav-tabs li .sidebar-nav-link.active {
  background-color: #ff3514;
  color: #fff;
}

.oc-sidebar-main {
  padding: 25px 15px;
}

@media (max-width: 1023.98px) {
  .oc-sidebar {
    width: 100%;
  }
  .oc-sidebar-back {
    width: 100%;
  }
}

.export-options .export-item {
  background-color: #fff;
  border: 1px solid #CFCFCF;
  color: #6A6971;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 20px;
  width: 100%;
}

.export-options .export-item:hover {
  background-color: #E5E5E5;
}

.export-options .export-item-icon {
  color: #6A6971;
  margin-right: 5px;
}

/* Content */
#root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.data-wrapper, .wrapper, #root {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  overflow: hidden;
  position: relative;
}

.hero-section {
  color: #fff;
  height: 300px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.hero-section img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
  width: 100%;
}

.hero-section-text {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  margin: 0 auto;
  max-width: 500px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
}

.hero-section-text h1 {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 300;
  line-height: 36px;
  margin-bottom: 20px;
}

.hero-section::after {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

@media (max-width: 767.98px) {
  .hero-section {
    height: 150px;
  }
  .hero-section-text {
    padding: 0 20px;
  }
  .hero-section-text h1 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 26px;
  }
}

.content-section {
  padding: 35px 0;
}

.ic-content {
  height: 100%;
  margin-left: 300px;
}

.ic-content-full {
  margin-left: 0;
}

.ic-content-header {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 70px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px 30px;
}

.ic-content-header-inner {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.ic-content-header-inner .view-filter .MuiFormControl-root .MuiInputLabel-root {
  font-family: inherit;
}

.ic-content-header-inner .view-filter .MuiFormControl-root .MuiOutlinedInput-root {
  font-family: inherit;
}

.ic-content-header-inner .view-filter .MuiFormControl-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 19px;
  width: auto;
}

.ic-content .ic-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 30px;
  position: relative;
}

.ic-content .ic-body-content {
  float: left;
  width: 100%;
}

.ic-content .ic-body-content .selected-filters {
  color: #6A6971;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
}

.ic-content .ic-body-content .MuiChip-root {
  background-color: #2E3BAE;
  border-radius: 30px;
  color: #fff;
  margin-right: 5px;
  padding: 15px 10px;
}

.ic-content .ic-body-content .MuiChip-root .MuiChip-label {
  font-weight: 500;
}

@media (max-width: 1023.98px) {
  .ic-content {
    margin-left: 0;
  }
}

@media (max-width: 767.98px) {
  .ic-content-header {
    height: auto;
    padding: 20px;
  }
  .ic-content-header-inner {
    display: block;
  }
  .ic-content-header-inner .MuiAutocomplete-root {
    margin-top: 20px;
  }
  .ic-content .ic-body {
    padding: 20px;
  }
  .oc-sidebar {
    display: none;
  }
  .oc-sidebar.shown {
    background-color: #fff;
    display: block;
    height: 100%;
    position: absolute;
    z-index: 10;
  }
  .oc-sidebar-back {
    display: none;
    z-index: -1;
  }
  .oc-sidebar-back.shown {
    display: block;
    z-index: 0;
  }
}

.filter-toggle {
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 20;
}

.filter-toggle button {
  border-radius: 0;
}

@media (min-width: 992px) {
  .filter-toggle {
    display: none;
  }
}
