
.card {
    margin: 0 auto;
    padding: 30px;
    /* margin-top: 30px; */
    display: flex;
    flex-direction: column;
}

.content1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px;
    margin-top: 5%;
    gap: 26px;
}

.h1 {
    font-family: 'Inter';
    font-style: normal;
    color: #268706;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: -10px;
}

.txt1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-top: -5px;
    margin-right: 60px;
}

.numbers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
}

.bi {
    font-size: 80px;
    color: #268706;
}

.content2 {
    padding-top: 20%;
}

table{
    width: 100%;   
}

.border-bottom-table{
    border-collapse: collapse;
}
.border-bottom-table td {
    border-bottom: 1px solid black;
    padding: 20px;
    padding-left: 0px;
}
td.numb{
    font-size: 24px;
    text-align: right;

}
